@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');

* {
  box-sizing: border-box;
  --maincolor: #FA5456;
  --font: #434343;
  --font2: #737373;
  --inputback: #fafafa;
  --infofont: #3b71b0;
  --orange: #ffb448;
  --selected: #fed21a;
  --brown: #d38b23;
  --yellow: #f8eede;
  --gray: #afafaf;
  --red: #a5243d;
  --lightred: #ffeeee;
  --blue: #07b2bd;
  --gold: #fed21a;
  --lightorange: #e2c0c0;
  --darkGreen: #39b970;
  --lightGray: #8b8d9f;
  --whiteGray: #b3b5c5;
  --green: #07b2bd;
  --vLightGray: #bcbcbc;
  --darkOrange: #fe5c36;
  --regularGray: #d9d9d9;
  --white: #ffff;
  --black: black;
  --font2: #6a747d;
  --darkRed: #e54335;
  --redColor:#FF5B5B;
}

html {
  font-family: 'Noto Sans JP', sans-serif;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

.LoaderDiv {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 5;
}
