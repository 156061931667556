.containerPayment {
  width: 100%;
  display: flex;
  justify-content: center;
}
.sectionPayment {
  width: 90%;
  align-items: center;
  text-align: center;
}
.imgPayment {
  width: 200px;
  height: auto;
}
.imgFailedPayment {
  width: 350px;
  height:auto;
}

.cartDataPaymet {
  margin-top: 40px;
}

.titleStatus {
  margin-top: 20px;
  font-weight: bold;
}

.freeContainer {
  border-radius: 15px;
  width: 80%;
  border-width: 2px;
  /* border-color: red; */
  justify-content: 'center';
  /* border: 3px solid  */
  margin-left: 50%;
  transform: translateX(-50%);
  text-align: left;
  padding: 12px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.titles {
  color: var(--maincolor);
}

.subTitles {
  color: var(--font);
  margin-left: 10px;
  font-size: 25px;
}
