.actionButtonContainer{
    
    
    border-radius: 7px;
    background-color: #fff;
    color: #FA5456;
}

.actionButton{
    color: #FA5456;
    font-size: 1.5rem;
}