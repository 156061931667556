.lessonTitle h4,
.languages h4 {
  font-weight: bold;
  color: #6e6e6e;
}
.leave-feedback {
  margin-right: 10px;
}
.leave-feedback button {
  padding: 0px 20px;
}
.slotContainer {
  border: 2px solid #FA5456;
  padding: 10px 20px;
  border-radius: 15px;
  position: relative;
  background-color: yellow;
  cursor: pointer;
}

.rightBtn {
  color: rgb(251, 84, 86);
  background: #e9fcf8;
  position: absolute;
  right: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 15px 15px 0px;
  padding: 0 10px;
}
