.TrackingDetails{
    padding: 0 2rem 0 0;
}
.ActionButtons{
    display: flex;   
}
.SecondBottom{
    margin-left: 1rem;
}
.mainParagraph{
    color: #6e6e6e;
    font-size: 1.1rem;
}
.TrackVideo{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    margin-bottom: 25px;
    border: none;
}
.TrackImgContainer,
.CardImgContainer{
    overflow: hidden;
    height: auto;
    position: relative;
    border-radius: 10px;
}

@media only screen and (max-width: 699px) {
    .TrackingDetails{
        order: 2;
        padding: 0 1.5rem;
    }
    .TrackingMedia{
        order:1
    }
}

/*checkbox*/

  .AuctionType ul {
    list-style-type: none;
  }
  
  .AuctionType li {
    display: inline-block;
  }
  
  .AuctionType input[type="radio"][id^="name"] {
    display: none;
  }
  
  .AuctionType label {
    display: block;
    position: relative;
    margin: 10px 20px;
    cursor: pointer;
    
  
  }
  
  .AuctionType label:before {
    background-color: white;
    color: white;
    content: " ";
    display: block;
    border-radius: 50%;
    border: 1px solid #FA5456;
    position: absolute;
    top: 15px;
    right: 15px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 28px;
    transition-duration: 0.4s;
    transform: scale(0);
  }
  
  .AuctionType label img {
    transition-duration: 0.2s;
    transform-origin: 50% 50%;
  }
  
  .AuctionType :checked + label img {
    border: 2px solid #FA5456;
    padding: 3px
  }

  .AuctionType :checked + label h6 {
    color: #FA5456;
  }
  
  .AuctionType :checked + label:before {
    
  }
  
  .AuctionType :checked + label img {
    z-index: -1;
  }

  .ArrowDate{
    margin: 0 35px;
}