.HeaderTitle{
    color: #24A1AB;
    font-weight: 700;
    font-size: 54px;
}
.HeaderTitle span{
    color:#FF5B5B
}
.FormContainer{
    padding: 0;
    max-width: 100%;
    overflow: hidden;
}

.Background{
    width:100%
}

.FormPadding {
    padding: 1rem 2rem;
  }
  @media (max-width: 768px) {
    .FormPadding {
      padding: 2rem;
    }
  }

.TextArea{
    padding: 14px 25px;
    margin: 0px 5px;
    border-radius: 10px;
    border: 1px solid #192252;
    color: #181C32;
    width: 100%;
    font-weight: 400;
    font-size: 14px;
}
.ValidationMessage{
    font-size: 0.8rem;
    text-align: right;
}
.Contacts{
    margin-top: 4rem;
}
.Contacts h6{
    margin-top: 30px;
    font-weight: 600;
}
.Contacts img{
    width: 45px;
    margin: 10px;
}
