.side-menu-bar nav{
	position:absolute;
	top:0;
	left:0;
	display:flex;
	flex-direction:column;
	width:21%;
	min-height: 100vh;
	background-color: #fff;
	padding:2rem 1rem 1rem 1rem;
	transition: transform 1s;
    z-index: 3;
}

.side-menu-bar .navbar{
	transform: translateX(-100%);
}

.side-menu-bar .nav-btn{
	position: absolute;
    transform: translateX(38px);
    top: 45px;
    right: 0px;
    width: 40px;
    height: 40px;
    
    border-bottom-right-radius: 5px;
	border-top-right-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0acad7;
    outline: none;
    border: none;
    font-size: 1rem;
    padding: 5px 10px;
    cursor: pointer;
    color: #fff;
}

.side-menu-bar .logo{
	display: block;
	width: 100px;
	margin: auto;
	background:transparent;
}

.side-menu-bar .logo img{
	display: block;
	width: 60px;
	height: 100%;
}

.side-menu-bar .nav-link{
	display: flex;
	align-items: center;
	color: #000;
	text-decoration: none;
	padding: 5px 0rem;
	border-radius: 5px;
	margin-bottom: 5px;
}

.side-menu-bar .nav-link:hover{
	background-color:#AFB4FF;
	color: #4e4e4e;
}

.side-menu-bar .nav-link span{
	margin-left: 10px;
}

.side-menu-bar .nav-top{
	margin-top: 2rem;
}

.side-menu-bar .nav-top .main-head p:first-child{
	font-size: 23px;
}

.side-menu-bar .nav-top .main-head p:last-child{
	color: #b8b9bb;
	font-size: 15px;
}

.side-menu-bar .mobile-nav{
	background-color:#753ffd;
	width: 100%;
	height:40px;
	display: none;
}

.side-menu-bar .mobile-nav-btn{
	color: #fff;
	background: transparent;
	outline: none;
	border: none;
	margin: 0 10px;
}

.side-menu-bar .store-icons img{
	border-radius: 15px;
}

@media only screen and (max-width: 768px){
	.side-menu-bar nav{
		width: 40%;
		padding: 10px;
	}
}

@media only screen and (max-width: 450px){
	.side-menu-bar .mobile-nav{
		display: flex;
		align-items: center;
	}
	.side-menu-bar nav{
		width: 70%;
		top: 0px;
		min-height: calc(100vh - 38px);
	}

	.side-menu-bar .nav-btn{
		display: none;
	}
}

.navbar{
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}