.react-calendar {
  width: 100%;
  border: 2px solid rgb(251, 84, 86);
  overflow: hidden;
  border-radius: 15px;
  padding: 1rem;
}
.react-calendar__tile {
  padding: 15px 6.6667px;
}
.react-calendar__tile--active,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #fff;
}
.react-calendar__tile--active abbr {
  background: rgb(251, 84, 86);
  border-radius: 50%;
  padding: 10px;
}
