.Card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;  
    margin: 10px 0
  }
  
  .Card .Percent {
    position: relative;
  }
  
  .Card svg {
    position: relative;
    width: 70px;
    height: 70px;
    transform: rotate(-90deg);
    scale: 1.5;
  }
  
  .Card svg circle {
    width: 100%;
    height: 100%;
    fill: none;
    
    stroke-width: 1;
    stroke-linecap: round;
  }
  
  .Card svg circle:last-of-type {
    stroke-dasharray: 208px;
    stroke-dashoffset: calc(208px - (208px * var(--percent)) / 100);
    stroke: #FA5456; 
    stroke-width: 4px
  }
  
  .Card .Number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .Card .Number h3 {
    font-size: 28px;
    margin: 0;
  }
  
  .Card .Number h3 span {
    font-size: 28px;
  }


  @media (max-width: 768px) {
    .Card svg{
      scale: 1;
    }
    .Card .Number h3 {
      font-size: 20px;
    }
    .Card .Number h3 span{
      font-size: 20px;
    }
  }
  