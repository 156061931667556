.Textarea{
    padding: 10px 20px;
    border-radius: 15px;
    border: solid 1px #efefef;
    background-color: #fafafa;
}

/*checkbox*/

.AuctionType ul {
    list-style-type: none;
  }
  
  .AuctionType li {
    display: inline-block;
  }
  
  .AuctionType input[type="radio"][id^="name"] {
    display: none;
  }
  
  .AuctionType label {
    display: block;
    position: relative;
    margin: 10px 20px;
    cursor: pointer;
    
  
  }
  
  .AuctionType label:before {
    background-color: white;
    color: white;
    content: " ";
    display: block;
    border-radius: 50%;
    border: 1px solid #FA5456;
    position: absolute;
    top: 15px;
    right: 15px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 28px;
    transition-duration: 0.4s;
    transform: scale(0);
  }
  
  .AuctionType label img {
    transition-duration: 0.2s;
    transform-origin: 50% 50%;
  }
  
  .AuctionType :checked + label img {
    border: 2px solid #FA5456;
    padding: 3px
  }

  .AuctionType :checked + label h6 {
    color: #FA5456;
  }
  
  .AuctionType :checked + label:before {
    
  }
  
  .AuctionType :checked + label img {
    z-index: -1;
  }

  
.ProgressValue{
  position: absolute;
  top: -3.5rem;
  border-radius: 16px;
  padding: 3px 15px;
  z-index: 1;
  margin: 0;
}
.ProgressValue:after {
  
  content: '';
  content: '';
  position: absolute;
  bottom: -17px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-top: solid 15px #FA5456;
  border-left: solid 15px transparent;
  border-right: solid 15px transparent;
}

.SubmitBtn{ 
  padding: .375rem .75rem;
    outline: none;
    border: 1px solid #eee;
    margin: 0 5px;
    border-radius: .375rem;
    color: #fff;
    background: #FA5456;
}

.CheckBoxesContainer [type='radio']:checked,
.CheckBoxesContainer [type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}
.CheckBoxesContainer [type='radio']:checked + label,
.CheckBoxesContainer [type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
.CheckBoxesContainer [type='radio']:checked + label:before,
.CheckBoxesContainer [type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 2px;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
.CheckBoxesContainer [type='radio']:checked + label:after,
.CheckBoxesContainer [type='radio']:not(:checked) + label:after {
  content: '';
  width: 10px;
  height: 10px;
  background: #FA5456;
  position: absolute;
  top: 6px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.CheckBoxesContainer [type='radio']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.CheckBoxesContainer [type='radio']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.CheckBox {
  display: inline-block;
  margin-right: 15px;
}