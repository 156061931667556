.CustomCheckbox {
    position: relative;
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.CustomCheckbox input {
    position: absolute;
    width: 0;
    left: 50px;
    height: 0;
    opacity: 0;
    cursor: pointer;
}

.CustomCheckbox .Checkmark {
    position: relative;
    display: block;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    background: white;
    border-radius: 3px;
    outline: 1px solid #acacac;
    transition: all 0.2s ease;
}

.CustomCheckbox:hover .Checkmark {
    background: #f4f4f5;
    transition: all 0.2s ease;
}

.CustomCheckbox input:checked ~.Checkmark {
    background: #FA5456;
    outline: 1px solid #FA5456;
}

.CustomCheckbox input[type="radio"] ~ .Checkmark {
    border-radius: 50%;
}

.CustomCheckbox .Checkmark::after {
    position: absolute;
    display: block;
    content: "";
    left: 50%;
    top: 40%;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -50%) rotate(45deg);
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    -moz-transform: translate(-50%, -50%) rotate(45deg);
    -ms-transform: translate(-50%, -50%) rotate(45deg);
    opacity: 0;
    transition: all 0.2s ease;
}

.CustomCheckbox input:checked~.Checkmark::after {
    opacity: 1;
    transition: all 0.2s ease;
}