.story-card{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    margin-bottom: 35px;
    border: none;
    cursor: pointer;
}
.story-card-title {
    font-weight: bold;
    font-size: 18px;
    color: #7b7b7b;
}

.story-user{
    position: absolute;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    left: 50%;
    bottom: -2rem;
    transform: translate(-50%);
}