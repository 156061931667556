.card-button{
    text-align: center;
    width: 100%;
    background-color: #FF5659;
    outline: none;
    border: 2px solid #FF5659;
    border-radius: 8px !important;
    padding: 15px 40px;
    font-weight: 600;
}
.card-button:hover,
.card-button:active{
    background-color: #FF5659 !important; 
    border: 2px solid #FF5659;
}