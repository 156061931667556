.Day{
    text-align: center;
    width: 70px;
    height: 70px;
    border: 1px solid #f1f1f1;
    flex-direction: column;
    justify-content: center;
    border-radius: 17px;
    margin: 0px 10px;
    cursor: pointer;
    display: inline-block;
    padding: 5px 20px;
    text-wrap: nowrap;
}
.ActiveDay{
    background-color: #FA5456;
    color: #fff;
    border-radius: 17px;
    border: none;
    width: 80px;
    transition: all .1s ease-in;
    margin: 0;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ProgressValue{
    position: absolute;
    top: -3.5rem;
    border-radius: 16px;
    padding: 3px 15px;
    z-index: 1;
    margin: 0;
}
.ProgressValue:after {
    
    content: '';
    content: '';
    position: absolute;
    bottom: -17px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-top: solid 15px #FA5456;
    border-left: solid 15px transparent;
    border-right: solid 15px transparent;
}