.CardTitle {
  background-color: #FA5456;
  border: 2px solid #FA5456;
  border-radius: 15px;
  padding: 10px 40px;
  color: #fff;
  cursor: pointer;
}

.Item{
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 10px;
  margin-bottom: 25px;
  cursor: pointer;
  position: relative;
  margin: 0 1rem 2rem 0;
  padding: 1rem;
  background-color: #fff;
}
.Owner{
  position: absolute;
  right: 0;
  top: 0;
  display: inline;
  width: auto;
  color: #fff;
  border-radius: 0px 10px;
  font-size: 1.2rem;
  font-weight: bold;
}
.Level{
  color: #07b2bd;
  display: inline-block;
  font-weight: 500;
  padding: 0.5rem 1rem;
  border-radius: 15px;
  font-size: 1.1rem;
  background: #f7c700;
}
.Image{
  padding: 0 !important;
}
.Image img{
  border-radius: 50%;
  max-width: 240px;
  border-radius: '10px';
}
.Details{
  padding: 30px 5px;
}

.Details h4{
  font-weight: 500;
  font-size:18px;
  color: #07b2bd;
  margin: 15px 0 10px;
}

.Details > p{
  color: #b3b3b3;
  font-weight: 500;
  font-size: .9rem;
}
.Details .Date{
  color: #08cad7;
  font-weight: bold;
}
.Price{
  color: #fff;
  background-color: #07b2bd;
  display: inline-block;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 15px;
  font-size: 1.1rem;
}

@media only screen and (max-width: 699px) {
  .Image img{
      max-width: 100% !important;
      width: 100% !important;
      height: 110px !important;
  }

  .Details{
      padding: 10px 5px;
  }
  .Date > span{
      font-size: 12px;
  }
  .Date > span > img{
      margin-right: 5px !important;
      width: 17px !important;
  }
  .Price,
  .Level{
      font-size: 12px;
  }
}

.ArrowDate{
  margin: 0 35px;
}

.Provider{
  width: fit-content;
}
.Start p,
.End p{
  font-size: 18px;
}