.Container {
  padding: 1rem 2rem;
  border: 1px solid #d0d0d0;
  border-radius: 10px;
}
.CheckBoxContainer {
  border: 1px solid #d0d0d0;
  border-radius: 10px;
  padding: 1rem 2rem;
  margin-bottom: 15px;
}
.PriceBoxContainer,
.TimeBoxContainer {
  border: 1px solid #d0d0d0;
  border-radius: 10px;
  padding: 1rem 2rem;
  margin-bottom: 15px;
}
.PriceBoxContainer input,
.PriceBoxContainer input:focus,
.PriceBoxContainer input:focus-visible,
.TimeBoxContainer input,
.TimeBoxContainer input:focus,
.TimeBoxContainer input:focus-visible {
  border: none;
  outline: none;
}

.enableAvailability {
  position: relative;
}

.enableAvailability input[type='checkbox'] {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.enableAvailability input[type='checkbox']:hover + label:after {
  box-shadow: 0 2px 15px 0 #0002, 0 3px 8px 0 #0001;
}
.enableAvailability input[type='checkbox']:checked + label:before {
  background: #FA5456;
}
.enableAvailability input[type='checkbox']:checked + label:after {
  background: #fff;
  left: 16px;
}
.enableAvailability label {
  position: relative;
  display: flex;
  align-items: center;
}
.enableAvailability label:before {
  content: '';
  background: #bbb;
  height: 12px;
  width: 35px;
  position: relative;
  display: inline-block;
  border-radius: 46px;
  transition: 0.2s ease-in;
}
.enableAvailability label:after {
  content: '';
  position: absolute;
  background: #fff;
  top: -4px;
  width: 19px;
  height: 19px;
  left: 0;
  border-radius: 50%;
  z-index: 2;
  box-shadow: 0 0 5px #0002;
  transition: 0.2s ease-in;
}

.HeaderTitle {
  margin: 1rem 0;
  text-align: center;
  border-bottom: 1px solid #e3e3e3;
  line-height: 0.1em;
}

.HeaderTitle span {
  background: #fff;
  padding: 0 10px;
}