.language{
    padding: 7px 15px;
    margin: 10px 10px 10px 0px;
    border-radius: 15px;
    background-color: #f9eede;
    color: #d38b23;
}

@media only screen and (max-width: 768px){
    .language{
        padding: 7px 8px;
        margin: 0 5px;
        font-size: 14px;
    }
}
