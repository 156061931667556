.course-item{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    margin-bottom: 25px;
    cursor: pointer;
    position: relative;
}
.course-type{
    position: absolute;
    right: 0;
    top: 0;
    display: inline;
    width: auto;
    color: #fff;
    padding: 7px 40px;
    border-radius: 0px 10px;
    font-size: 1.2rem;
    font-weight: bold;
}
.live-course-type{
    background-color: #FA5456;
}
.recorded-course-type{
    background-color: #FF5B5B;
}
.course-image{
    padding: 0 !important;
}
.course-image img{
    border-radius: 10px;
    max-width: 240px;
}
.course-details{
    padding: 30px 30px !important;
}

.course-details h4{
    font-weight: bold;
    font-size:18px;
    color: #7b7b7b;
}

.course-details > p{
    color: #b3b3b3;
    font-weight: 500;
    font-size: .9rem;
}
.course-details .course-provider{
    color: #08cad7;
    font-weight: bold;
}



.rate-number{
    color: #7b7b7b;
    font-weight: 600;
    font-size: 1.15rem;
    margin-left: 10px;
}

.course-views{
    color: #b3b3b3;
    font-weight: 500;
    font-size: 1.2rem;
}
.course-views-icon{
    margin-right: 5px;
}
.course-price{
    font-size: 1.3rem;
    font-weight: bold;
    text-align: right;
    padding: 50px 2rem !important;
}

.course-price .discount-price{
    color:#FA5456
}

.course-price .real-price{
    text-decoration: line-through;
    color: #b3b3b3;
    text-decoration-thickness: 2px;
}