.pagination {
  text-align: center;
}
.pagination .page-item a {
  color: #07b2bd;
}
.pagination .page-link {
  min-width: 40px;
  min-height: 40px;
  border-radius: 50% !important;
  margin: 0 10px;
}
.pagination .active > .page-link {
  background-color: var(--redColor);
  border-color: var(--redColor);
}
.disabled>.page-link, .page-link.disabled{
  background-color: transparent;
}