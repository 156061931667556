.TrackingDetails{
    padding: 0 2rem 0 0;
}
.ActionButtons{
    display: flex;   
}
.SecondBottom{
    margin-left: 1rem;
}
.mainParagraph{
    color: #6e6e6e;
    font-size: 1.1rem;
}
.TrackVideo{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    margin-bottom: 25px;
    border: none;
}
.TrackImgContainer,
.CardImgContainer{
    overflow: hidden;
    height: auto;
    position: relative;
    border-radius: 10px;
}

@media only screen and (max-width: 699px) {
    .TrackingDetails{
        order: 2;
        padding: 0 1.5rem;
    }
    .TrackingMedia{
        order:1
    }
}

/*checkbox*/

  .AuctionType ul {
    list-style-type: none;
  }
  
  .AuctionType li {
    display: inline-block;
  }
  
  .AuctionType input[type="radio"][id^="name"] {
    display: none;
  }
  
  .AuctionType label {
    display: block;
    position: relative;
    margin: 10px 20px;
    cursor: pointer;
    
  
  }
  
  .AuctionType label:before {
    background-color: white;
    color: white;
    content: " ";
    display: block;
    border-radius: 50%;
    border: 1px solid #FA5456;
    position: absolute;
    top: 15px;
    right: 15px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 28px;
    transition-duration: 0.4s;
    transform: scale(0);
  }
  
  .AuctionType label img {
    transition-duration: 0.2s;
    transform-origin: 50% 50%;
  }
  
  .AuctionType :checked + label img {
    border: 2px solid #FA5456;
    padding: 3px
  }

  .AuctionType :checked + label h6 {
    color: #FA5456;
  }
  
  .AuctionType :checked + label:before {
    
  }
  
  .AuctionType :checked + label img {
    z-index: -1;
  }

  .ArrowDate{
    margin: 0 35px;
}

.HeaderTitle{
  color: #08cad7;
  font-weight: 600;
}
.HeaderPargraph{
  color: #b3b3b3;
  font-weight: 600;
}
.FormContainer{
  padding: 0;
  max-width: 100%;
  overflow: hidden;
  background: #f9f9f9;
}

.FormPadding{
  padding: 2rem 10rem;
}

.TopImg{width: 300px;}

.FormInput{ 
  height: 48px;
  padding: 14px 25px;
  margin: 0px 5px;
  border-radius: 15px;
  background-color: #fff;
  border: none;
  color: #848484;
}

.togglePassword{
  position: absolute;
  display: inline-block;
  right: 15px;
  top: 10px;
}

.SendCode{
  color: #08cad7;
  text-decoration: underline !important;
}

/* The container */
.CheckBoxContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 17px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.CheckBoxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.Checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid #FA5456;
  border-radius: 4px;
}


/* When the checkbox is checked, add a blue background */
.CheckBoxContainer input:checked ~ .Checkmark {
  background-color: #FA5456;
  
}

/* Create the Checkmark/indicator (hidden when not checked) */
.Checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the Checkmark when checked */
.CheckBoxContainer input:checked ~ .Checkmark:after {
  display: block;
  
}

/* Style the Checkmark/indicator */
.CheckBoxContainer .Checkmark:after {
  left: 6px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  
}

.TermsAndConditions{
  text-decoration: underline;
  color: #FA5456;
  font-weight: 600;
}

.InviteCode{
  border: 1px solid #d9d9d9;
  border-radius: 15px;
  padding: 2rem;
}

.InviteCode h5{
  margin-bottom: 15px;
}


.InviteHint{
  color: #FA5456;
  opacity: .9;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .TopImg{width: 200px;}
  .FormPadding{padding: 2rem;}
}



.Drop{ 
height: 48px;
padding: 14px 25px;
margin: 0px 5px;
border-radius: 15px;
background-color:white;
border: none;
color: #848484;
}
.Drop:hover,
.Drop:active,
.btn:first-child:active,
.Drop.btn.show{
background-color:white !important;
color: #848484 !important;
}


.Day{
  text-align: center;
  width: 70px;
  height: 70px;
  border: 1px solid #f1f1f1;
  flex-direction: column;
  justify-content: center;
  border-radius: 17px;
  margin: 0px 10px;
  cursor: pointer;
  display: inline-block;
  padding: 5px 20px;
  text-wrap: nowrap;
}
.ActiveDay{
  background-color: #FA5456;
  color: #fff;
  border-radius: 17px;
  border: none;
  width: 80px;
  transition: all .1s ease-in;
  margin: 0;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}