.UploadBtnWrapper{
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
}

.UploadBtnWrapper button {
    border: 3px dashed #FA5456;
    color: gray;
    background-color: white;
    padding: 8px 20px;
    border-radius: 15px;
    font-size: 20px;
    font-weight: bold;
    
  }
  
  .UploadBtnWrapper input{
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }

  .FilePreview{
    border: 2px solid #FA5456;
    padding: .7rem 1rem;
    border-radius: 15px;
    margin: 2rem 0;
  }
  .FileName{
    color: #FA5456;
    font-weight: 600;
    font-size: 18px;
  }

.SubmitButton{
    text-align: center;
    width: 100%;
    background-color: #FA5456;
    outline: none;
    border: 2px solid #FA5456;
    border-radius: 15px;
    padding: 10px 35px;
    font-weight: 600;
    font-size: 1.2rem;
    
  }
  .SubmitButton:hover,
  .SubmitButton:active{
    background-color: #FA5456 !important; 
    border: 2px solid #FA5456;
  }
  
  .SubmitButton:disabled{
    background-color: #919191 !important;
    border: 2px solid #919191;
    color: #fff;
    font-size: 1.2rem;
  }

  .LoaderDiv{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height:100%;
    background: rgba(0,0,0, 0.1);   
    z-index: 5;
  } 
 