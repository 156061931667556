@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .Container{
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0 5rem;
    min-height: 10rem;
  }
  .LoadingSpinner {
    animation: spinner 1.5s linear infinite;
    /* position: absolute; */
    z-index: 1000;
    width: 90px;
    height: auto;
    margin-top: 100px;
    /* margin-inline: 25%; */
    /* margin-block: 25%; */
  }