.page-title{
    font-weight: bold;
    color: #6e6e6e;
    padding: 5rem 0 2rem;
}
.custom-web-tabs.nav-tabs{
    border: none;
}
.custom-web-tabs.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover{
    border-color: #fff
}
.custom-web-tabs.nav-tabs .nav-link{
    padding-right: 3rem;
    padding-left: 3rem;
    font-weight: 600;
    color: #7b7b7b;
    font-size: 1.2rem;

}
.custom-web-tabs.nav-tabs .nav-link.active{
    color: #FA5456;
    border:none;
    border-bottom: 2px solid #FA5456;
    font-weight: 600;
    background: transparent;

}

.custom-web-tabs.nav-tabs .nav-link{
    padding-right: 1rem;
    padding-left: 1rem;
    font-weight: 600;
    color: #7b7b7b;
    font-size: 1rem;
}




@media only screen and (max-width: 699px) {
    .page-title{
        padding: 1rem 1rem 0;
    }
    .custom-web-tabs.nav-tabs{
        margin-bottom: 1rem !important;
    }
}