.lessonContainer{
    padding: 0;
    max-width: 100%;
    overflow: hidden;
}
.lessonTopHeader{
    padding: 20px;
}
.lessonTitle h4{
    font-weight: bold;
    color: #6e6e6e;
}
.leaveFeedback{
    margin-right: 10px;
}
.leaveFeedback button{
    padding: 0px 20px;
}

@media (max-width: 768px) {
    .lessonTopHeader{flex-direction: column;}
    .cardProgressContainer{padding: 1rem 3rem;}
    .lessonContainer{
        padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    }
}
