.active{
    color:#FA5456;
    font-weight: 600;
    font-size: 17px;
    
}
.MainNavHeaderLink{
    font-weight: 400;
    color: #000;
    padding: 20px 5px;
    font-size: 14px;
    text-decoration: none;
    display: inline-block;
}
.NavBar{
    background: #fff;
    height: 100%;
}
.Logo{
    width: 141px;
    overflow: hidden;
}
.Logo img{
    width: 100%;
}
.Switcher{
    background: #08cad7;
    width: 45px;
    height: 45px;
    border-radius: 5px;
    padding: 5px;
}

@media (max-width: 699px) {
    .MainNavHeaderLink{
        padding: 10px 5px;
        font-size: 17px;
        display: block;
    }
    .NoramlNavItem{
        display: inline-block;
    }
    .active{
        padding: 10px 5px;
    }
  }