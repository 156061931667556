.CheckoutDetails{
    padding: 0 2rem 0 0;
}
.ChackoutContainer{
    padding: 0 5rem;
}
.CheckoutDetails h6{
    color: #6a6f73;
    font-weight: 700;
    margin-bottom: 0;
}
.CheckoutDetails > div{
    margin-bottom: 1rem;
}


.PromoCodeContainer,
.WalletContainer,
.PaymentMethodContainer,
.SummaryContainer{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    margin-bottom: 25px;
    padding: 1rem 2rem;
}
.PromoCodeContainer h4,
.WalletContainer h4,
.PaymentMethodContainer h4,
.SummaryContainer h4{
    color:#FA5456
}

.PromoCodeContainer img,
.WalletContainer img{
    width: 20px;
    vertical-align: baseline;
}
.PromoCodeContainer .Hint,
.WalletContainer .Hint{
    font-size: 14px;
    font-weight: 500;
    color: #939393;
}
.applyPromoCodeContainer{
    position: relative;
}
.applyPromoCodeInput{
    padding: 1rem;
    border-radius: 15px;
}
.applyPromoCodeBtnApplied{
    position: absolute;
    right: 5px;
    top: 10%;
    border: none;
    padding-block: 0.7rem ;
    border-radius: 15px;
    color: #fff;
    background: #FA5456;
    font-weight: bold;
    width: 120px;
}
.applyPromoCodeBtnNOTApplied{
    background: #bebebe;
    position: absolute;
    right: 5px;
    top: 10%;
    border: none;
    padding-block: 0.7rem ;
    border-radius: 15px;
    color: #fff;
    font-weight: bold;
    width: 120px;
}


.toggleWalletContainer{
    position: relative;
}

.toggleWalletContainer input[type=checkbox] {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
}
.toggleWalletContainer input[type=checkbox]:hover + label:after {
box-shadow: 0 2px 15px 0 #0002, 0 3px 8px 0 #0001;
}
.toggleWalletContainer input[type=checkbox]:checked + label:before {
background: #0ae1ef;
}
.toggleWalletContainer input[type=checkbox]:checked + label:after {
background: #08cad7;
left: 16px;
}
.toggleWalletContainer label {
position: relative;
display: flex;
align-items: center;
}
.toggleWalletContainer label:before {
content: "";
background: #bbb;
height: 12px;
width: 35px;
position: relative;
display: inline-block;
border-radius: 46px;
transition: 0.2s ease-in;
}
.toggleWalletContainer label:after {
content: "";
position: absolute;
background: #fff;
top: -4px;
width: 19px;
height: 19px;
left: 0;
border-radius: 50%;
z-index: 2;
box-shadow: 0 0 5px #0002;
transition: 0.2s ease-in;
}

.WalletContainer p span.WalletBalance{
font-weight: bold;
color:#939393;
margin-right: 2rem;
}


.CheckBoxesContainer [type="radio"]:checked,
.CheckBoxesContainer [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
.CheckBoxesContainer [type="radio"]:checked + label,
.CheckBoxesContainer [type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}
.CheckBoxesContainer [type="radio"]:checked + label:before,
.CheckBoxesContainer [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 14px;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}
.CheckBoxesContainer [type="radio"]:checked + label:after,
.CheckBoxesContainer [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #FA5456;
    position: absolute;
    top: 17px;
    left: 3px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.CheckBoxesContainer [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.CheckBoxesContainer [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.CheckBox{
    display: inline-block;
    margin-right: 15px;
}


.PaymentMethodContainer .Visa img{
    width: 50px;
    margin: 5px;
    height: 30px;
}

.PaymentMethodContainer .Paypal img{
    width: 100px;
    margin: 5px;
}

@media (max-width: 699px) {

    .ChackoutContainer{
        padding: 1rem;
    }
    .CheckoutDetails{
        padding: 0;
    }
    .PromoCodeContainer,
    .WalletContainer,
    .PaymentMethodContainer,
    .SummaryContainer{
        padding: 1rem;
    }
    .PaymentMethodContainer .Visa img{
        width: 20px;
        margin-right: 1px;
        height: 15px;
    }
    .PaymentMethodContainer .Paypal img{
        width: 80px;
        margin: 1px;
    }
    .Visa label,
    .Paypal label{
        padding-top: 10px;
    }
}