.MultiRangeCalendar {
  position: relative;
  border: 1px solid #b9b9b9;
  border-radius: 15px;
}
.selectDaysParagrah {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 25px;
}
.selectDaysParagrah img {
  margin-right: 10px;
}
.Clear {
  color: #00d7e5;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 7%;
}
