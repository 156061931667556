.course-title,
.course-header h5{
    font-weight: bold;
    color: #6e6e6e;
    
}
.course-header h5{
    margin-bottom: 2px;
}
.course-header p{
    color: #b3b3b3;
    font-weight: 500;
}
.course-description{
    color: #b3b3b3;
    font-weight: 500;
}
.course-provider{
    color: #08cad7;
    font-weight: bold;
    margin-bottom: 15px;
}

.course-content-sections{
    padding-right: 30px;
}

.section-container .collapse{
    padding: 10px 20px 10px 0;
}

.section-item-collapse{
    margin: 15px 0 15px 0px;
    padding: 15px;
    border-radius: 9px;
    box-shadow: 0 8px 24px 0 rgba(183, 183, 183, 0.17);
    background-color: #fff;
}

.section-title{
    margin-top: 0;
    margin-bottom: 0;
}

.section-item {
    align-items: flex-start;
    margin: 20px 0;
}

.section-item p{
    margin-bottom: 0;
}
.question-icon{
    /* padding: 10px 15px 15px; */
    margin-right: 10px;
    border-radius: 15px;
    background-color: none;
    
}
.question-icon img{
    width: 35px;
}
.lock-icon{
    border-radius: 7px;
    color: #FA5456;
    font-size: 1.2rem;
}

.lecture-type{
    color: #b3b3b3;
    font-weight: 500;
    font-size: .9rem;
}

.bankquestionItem .collaped-item svg{
    border-radius: 5px;
    background-color: #FA5456;
    color: #fff;
    padding: 5px;
    font-size: 25px;
}
.bankQuestionRates{
    color: #000;
    font-size: 20px;
}

.bankQuestionRates img{
    vertical-align: text-bottom;
}


@media (max-width: 768px) {
    .bankquestionItem.section-item-collapse{
        position: relative;
    }
    .bankquestionItem.section-item-collapse,
    .bankquestionItem .first-section{
        flex-direction: column;
        align-items: start !important;
    }
    .bankquestionItem .section-title{
        margin-top: 5px;
    }
    .bankquestionItem .question-icon img{
        margin-top: 10px;
    }
    .bankquestionItem .collaped-item{
        position: absolute;
        top: 1.2rem;
        right: 0.5rem;
    }
    .bankQuestionRates > div{
        display: flex;
        margin: 0 0 5px 5px !important;
        align-items: center;
    }
    .bankQuestionRates img{
        width: 15px;
        height: 15px;
    }
    .bankQuestionRates span{
        font-size: 17px;
    }
}
